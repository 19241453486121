import type { PropsWithChildren } from "react";

import { GateProvider as NestoGateProvider } from "@nestoca/gate-react";

import type { User, Role } from "@nestoca/gate-core";

interface GateProviderProps {
  permissions: string[];
  user?: User;
  role?: Role;
}

export const GateProvider = ({
  permissions,
  user = {},
  role,
  children,
}: PropsWithChildren<GateProviderProps>) => {
  return (
    <NestoGateProvider user={user} permissions={permissions} role={role}>
      {children}
    </NestoGateProvider>
  );
};
