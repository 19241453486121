export const DocumentCategories = [
  "ALL",
  "INCOMES",
  "IDENTIFICATION",
  "PROPERTIES",
  "FINAL_DOCUMENTS",
  "FINANCIALS",
  "LOW_RATE_GUARANTEE",
  "OTHER",
] as const;

export type DocumentCategory = typeof DocumentCategories[number];
