import { NavigationLink } from "@shared/ui/components/navigation-link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

export const MortgagesSecondarySidebarContent = () => {
  const { t } = useTranslation("common");

  const { pathname, query } = useRouter();

  const { referenceNumber } = query;

  return (
    <>
      <NavigationLink
        href={`/servicing/${referenceNumber}/loan-details`}
        active={
          pathname ===
          "/_tenants/[tenant]/servicing/[referenceNumber]/loan-details"
        }
      >
        {t`sidebar.loanDetails`}
      </NavigationLink>
      <NavigationLink
        href={`/servicing/${referenceNumber}/statements`}
        active={
          pathname ===
          "/_tenants/[tenant]/servicing/[referenceNumber]/statements"
        }
      >
        {t`sidebar.statements`}
      </NavigationLink>
      <NavigationLink
        href={`/servicing/${referenceNumber}/transactions`}
        active={
          pathname ===
          "/_tenants/[tenant]/servicing/[referenceNumber]/transactions"
        }
      >
        {t`sidebar.transactions`}
      </NavigationLink>
    </>
  );
};
