import { ApplicantDocumentsCounts } from "@shared/api/applications/applicants";

export interface DocumentCountWithApplicantId extends ApplicantDocumentsCounts {
  applicantId: number;
}

export const extendDocumentCountsWithApplicantId = (
  countsStateByApplicantId: Record<number, ApplicantDocumentsCounts>
): DocumentCountWithApplicantId[] => {
  return Object.keys(countsStateByApplicantId).map((applicantId) => {
    const counts = countsStateByApplicantId[+applicantId];
    return { ...counts, applicantId: +applicantId };
  });
};
