type StringOrUndefined = string | undefined;

const getInitials = (
  familyName: StringOrUndefined,
  givenName: StringOrUndefined
) =>
  `${givenName?.charAt(0) || ""}${familyName?.charAt(0) || ""}`
    .trim()
    .toUpperCase();

/**
 * Get the user initials base on the information provided by Auth0
 *
 * Usage:
 * ```
 * const initials = getUserInitials("Test", "Broker", undefined);
 * ```
 *
 * @param {StringOrUndefined} familyName - The user family name
 * @param {StringOrUndefined} givenName - The user given name
 * @param {StringOrUndefined} name - The user full name
 * @returns the user initials
 */
export const getUserInitials = (
  familyName: StringOrUndefined,
  givenName: StringOrUndefined,
  name: StringOrUndefined
) => {
  if (familyName && givenName) {
    return getInitials(familyName, givenName);
  }
  const splittedName = name?.split(" ");

  const firstName = splittedName?.shift();
  const lastName = splittedName?.pop();

  return getInitials(lastName, firstName);
};
