import {
  NavigationLinkProps as NavigationLinkPropsUI,
  NavigationLink as NavigationLinkUI,
} from "@nestoca/ui";
import Link from "next/link";

export type NavigationLinkProps = {
  href: string;
} & Omit<NavigationLinkPropsUI, "href">;

export const NavigationLink = ({
  children,
  href,
  ...rest
}: NavigationLinkProps) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <NavigationLinkUI {...rest}>{children}</NavigationLinkUI>
    </Link>
  );
};
