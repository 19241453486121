import dynamic from "next/dynamic";

export const PrimarySidebarContent = dynamic(
  () =>
    import("./primary-sidebar-content-ui").then(
      (mod) => mod.PrimarySidebarContent
    ),
  {
    ssr: false,
  }
);
